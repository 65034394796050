import { makeAutoObservable } from 'mobx'

import {
  DatasetItem,
  Fetch,
  ResponseDataset,
  ResponsePatchDataset,
} from 'types'
import { getDatasetId, getProjectId } from 'utils'
import { audioFormats, imageFormats, markerFromat, videoFormats } from 'app/constants'

import { datasets } from './store'


type Data = Partial<DatasetItem>

export class Dataset {
  fetch?: Fetch | null
  selected = false
  constructor(public data: Data = {}) {
    makeAutoObservable(this)
  }

  get id() {
    return this.data.id
  }

  get name(): string {
    return this.data.name ?? ''
  }

  get state(): number {
    return this.data.state ?? NaN
  }

  get config() {
    return this.data.config
  }

  get type() {
    return this.config?.display_component?.type
  }

  get project() {
    return this.data.project
  }

  get isHoneypot() {
    return this.data.is_honeypot
  }

  get total() {
    return {
      finished: this.data.stats_number_of_finished_records,
      markups: this.data.stats_number_of_markups,
      records: this.data.stats_number_of_records,
      skiped: this.data.stats_number_of_skipped_markups,
    }
  }

  get date() {
    return new Date(this.data.created_at ?? '').toLocaleDateString()
  }

  onChangeData(data: Data) {
    Object.assign(this.data, data)
  }

  setSelected(selected: boolean) {
    this.selected = selected
  }

  onCreate(files: File[], name?: string) {
    if (files.length === 0) return

    let url: Fetch['url'] = '/api/audio_dataset/create_from_structured_file/'
    const fileExt = `.${files[0].name.split('.').pop()}`

    if ([...audioFormats, ...imageFormats, ...videoFormats].indexOf(fileExt) >= 0) {
      url = '/api/audio_dataset/create_from_media_files/'
    }
    
    if (fileExt === markerFromat) {
      url = '/api/audio_dataset/create_from_mnf_file/'
    }

    if (fileExt === '.txt' && files.length > 1) {
      url = '/api/audio_dataset/create_from_media_files/'
    }

    const data = new FormData()

    data.set('name', name ? name : files[0].name)
    files.forEach(file => data.append('file', file))
    data.set('project', `https://platforma.razmetka.net/api/projects/${getProjectId()}/`)

    this.fetch = {
      method: 'POST',
      url,
      data,
      onResponse: () => (this.fetch = null),
      onSuccess: (res: ResponseDataset) => this.getDataset(res.body.id),
    }
  }

  getDataset(id?: string | number) {
    this.selected = false
    this.fetch = {
      method: 'GET',
      url: `/api/audio_dataset/${id}/`,
      onResponse: () => (this.fetch = null),
      onSuccess: (res: ResponseDataset) => {
        datasets.unshift(new Dataset(res.body))
      },
    }
  }

  onRemove() {
    this.selected = false
    this.fetch = {
      method: 'DELETE',
      url: `/api/audio_dataset/${this.id}/`,
      onSuccess: () => {
        datasets.remove(this)
      },
    }
  }

  onUpdate() {
    this.selected = false

    this.fetch = {
      method: 'PATCH',
      url: `/api/audio_dataset/${this.id}/`,
      data: this.data,
      onResponse: () => (this.fetch = null),
      onSuccess: (res: ResponseDataset) => {
        this.data = res.body
      },
    }
  }

  onPatchStateConfig(state: number) {
    this.selected = false
    this.fetch = {
      method: 'PATCH',
      url: `/api/audio_dataset/${this.id}/`,
      data: { state },
      onResponse: () => (this.fetch = null),
      onSuccess: (res: ResponsePatchDataset) => {
        const { validation_err_msg: errMsg, response } = res.body

        if (errMsg) {
          console.log(errMsg)
        } else {
          this.data.state = state
          this.data.config = response.config
        }
      },
    }
  }

  onStateChange() {
    if (this.state > 3) return

    if (this.state === 1) {
      this.onPatchStateConfig(2)
      return
    }
    if (this.state === 2) {
      this.onPatchStateConfig(3)
      return
    }
    if (this.state === 3) {
      this.onPatchStateConfig(2)
      return
    }

    this.data.state = this.state === 2 ? 3 : 2
    this.onUpdate()
  }
}
