import { makeAutoObservable } from 'mobx'
import { CheckboxProps } from '@mui/material'

import { DOMEN } from 'api'
import { getProjectId } from 'utils'
import { Fetch, ResponseDatasets, ResponseDataset } from 'types'

import { datasets } from './store'
import { Dataset } from './dataset'


export class Page {
  constructor(public fetch?: Fetch | null, public list = datasets) {
    this.getList()
    makeAutoObservable(this)
  }

  getList() {
    if (this.fetch) return

    this.fetch = {
      method: 'GET',
      url: '/api/audio_dataset/',
      query: { project_id: getProjectId() },
      onResponse: () => (this.fetch = null),
      onSuccess: (res: ResponseDatasets) => {
        datasets.replace(res.body.results.map((i) => new Dataset(i)))
      },
    }
  }

  downloadMarkup(downloadType: 'zip' | 'mnf' | 'coco' | 'yolo', isConsensus: true | false) {
    const getParams = () => {
			return isConsensus ? `?use_adaptive_consensus_algorithm=1&filetype=${downloadType}` : `?filetype=${downloadType}`
    }
    this.selected.forEach(pr => window.open(`${DOMEN}/api/auth/login/?next=/api/audio_dataset/${pr.data.id}/download/${getParams()}`))
  }

  createHoneypotDataset(dataset: Dataset, name: string, value: number, isFactor: boolean) {
    const data = new FormData()
    data.append('dataset', String(dataset.id))
    data.append('name', name)
    data.append('project', String(dataset.project))
    data.append('state', String(1))
    data.append('config', JSON.stringify(dataset.config))
    data.append(isFactor ? 'records_factor' : 'number_of_records', String(value))
  
    this.fetch = {
      data,
      method: 'POST',
      url: `/api/audio_honeypot_dataset/create_from_regular_dataset/`,
      onResponse: () => (this.fetch = null),
      onSuccess: (res: ResponseDataset) => datasets.unshift(new Dataset(res.body)),
      onError: e => console.log('error -> ', e)
    }
  }

  exportToHadoop() {
    if (this.fetch) return

    this.selected.forEach(pr => {
      this.fetch = {
        method: 'GET',
        url: `/api/audio_dataset/${pr.data.id}/export_mnf_to_hadoop/`,
        onResponse: () => (this.fetch = null),
        onSuccess: (res: ResponseDatasets) => {
          console.log('ok -> ', res)
        },
        onError: e => console.log('error -> ', e)
      }
    })
  }

  get hasMarkup() {
    return datasets.filter((i) => !i.fetch).some((i) => i.data.state === 3)
  }

  get selected() {
    return datasets.filter((i) => i.selected)
  }

  get allDatasets() {
    return datasets.filter(() => true)
  }

  get noHoneypotSelected() {
    return datasets.filter((i) => i.selected).filter((i) => !i.isHoneypot)
  }

  get hasSelected() {
    return Boolean(this.selected.length)
  }

  get indeterminate() {
    return this.hasSelected && this.selected.length !== datasets.length
  }

  get checkbox(): CheckboxProps {
    return {
      checked: this.hasSelected,
      indeterminate: this.indeterminate,
      onChange: ({ target }) => {
        datasets
          .filter((i) => !i.fetch)
          .forEach((i) => i.setSelected(target.checked))
      },
    }
  }

  onRemoveAll() {
    this.selected.forEach((i) => i.onRemove())
  }
}
