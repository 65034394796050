import { makeAutoObservable, observable, values } from 'mobx'
import { grey } from '@mui/material/colors'
import { CatalogItem, Fetch, ResponseCatalog, ResponseCatalogs } from 'types'
import { getProjectId } from 'utils'

type Data = Partial<CatalogItem>

export const catalogs = observable.map<number, Catalog>()

export class Catalog {
  fetch?: Fetch | null
  newCatalog?: Catalog | null
  showList = false
  cur: Data
  selected = false

  constructor(public data: Data = {}) {
    this.cur = data
    data.id && !data.parent && this.getList({ parent: data.id })
    makeAutoObservable(this)
  }

  get id(): number {
    return this.data.id ?? NaN
  }

  get parent() {
    return this.data.parent
  }

  get color() {
    return this.data.color || grey[500]
  }

  set color(color) {
    this.data.color = color
  }

  get name(): string {
    return this.data.name ?? ''
  }

  set name(name) {
    this.data.name = name
  }

  get description(): string {
    return this.data.description ?? ''
  }

  set description(description) {
    this.data.description = description
  }

  get parentList() {
    return values(catalogs).filter((c) => !c.parent)
  }

  get selectedParentList() {
    return this.parentList.filter((c) => c.selected)
  }

  get childList() {
    return values(catalogs).filter((c) => c.parent?.split('/').slice(-2)[0] === String(this.id))
  }

  get selectedChilList() {
    return this.childList.filter((c) => c.selected)
  }

  get hasChild() {
    return Boolean(this.childList.length)
  }

  get hiddenEl() {
    return this.id && this.newCatalog
  }

  onChange = (data: Data) => {
    Object.assign(this.data, data)
  }

  onToggleNewCatalog() {
    this.newCatalog = this.newCatalog
      ? null
      : new Catalog({
          color: this.color,
          parent: this.data.url,
          project: `http://platforma.razmetka.net/api/projects/${getProjectId()}/`,
        })
  }

  onToggleOpen() {
    this.showList = !this.showList
  }

  getList(query?: object) {
    this.fetch = {
      method: 'GET',
      url: '/api/audio_category/',
      query: { project_id: getProjectId(), ...query },
      onResponse: () => (this.fetch = null),
      onSuccess: (res: ResponseCatalogs) => {
        res.body.results.forEach((c) => {
          catalogs.set(c.id, new Catalog(c))
        })
      },
    }
  }

  onCreate() {
    this.fetch = {
      method: 'POST',
      url: '/api/audio_category/',
      data: this.newCatalog?.data,
      onResponse: () => {
        this.fetch = null
        this.newCatalog = null
      },
      onSuccess: (res: ResponseCatalog) => {
        catalogs.set(res.body.id, new Catalog(res.body))
      },
    }
  }

  onDelete() {
    this.selected = false
    this.fetch = {
      method: 'DELETE',
      url: `/api/audio_category/${this.id}/`,
      onSuccess: () => {
        this.childList.forEach((c) => catalogs.delete(c.id))
        catalogs.delete(this.id)
      },
    }
  }

  onUpdate() {
    this.selected = false
    this.fetch = {
      method: 'PATCH',
      url: `/api/audio_category/${this.id}/`,
      data: this.data,
      onResponse: () => (this.fetch = null),
      onSuccess: (res: ResponseCatalog) => {
        this.data = res.body
        this.cur = res.body
      },
    }
  }

  onClear() {
    catalogs?.clear()
  }
}
